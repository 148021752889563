import KontentSmartLink from '@kentico/kontent-smart-link';

const ksl = KontentSmartLink.initializeOnLoad({
  defaultDataAttributes: {
    projectId: window.kontentProjectId,
    languageCodename: window.kontentLanguageCodename
  },
  queryParam: 'preview'
}).then(() => {
  console.log('kontent smart link initialized');
});
